import React from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import useTheme from "@mui/system/useTheme";
import { flexCenter } from "themes/commonStyles";
import Image from 'next/image';

const Logo = () => {
  const theme = useTheme();

  return (
    <Link href="/">
      <Box sx={flexCenter} style={{ cursor: "pointer" }}>
          <Image src="/icons/house-person-svgrepo-com.svg" width="36" height="36" loading="lazy" />

          <Typography
          sx={{
            ml: 1,
            color: theme.palette.primary.main,
            fontWeight: "bold",
          }}
        >My perfect rent <span style={{color: '#e61e4d'}}></span>
        </Typography>
      </Box>
    </Link>
  );
};

export default Logo;
